.App.dark{
    background-color: #222;
    color: rgb(156,156,156);

    .chartGrid{
        stroke: rgba(228,228,228,0.217);
    }

    .table{
        background-color: #111;

        .tableCell{
            color: rgb(170, 170, 170);
        }
    }

    .navbar{
        color: #999;
        border-color: #333;
        .search{
            border-color: gray;
        }

    }

    .sidebar{
        background-color: #111;
        border-color: #333;

        
        hr{
            border-color: #333;
        }

        ul{
            li{
                &:hover{
                    background-color: #333;
                }
            }
        }
    }

    .datatable{
        .datagrid{
            color: gray;
            border: none;

            .viewButton{
                color: #999;

                &:hover{
                    color: white;
                }
            }
        }
    }

    input{
        background-color: transparent;
    }
}