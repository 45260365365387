.table{
    height: 500px;
    .cellWrapper{
        display: flex;
        align-items: center;

        .image{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
        }
    }

    .status{
        padding: 5px;
        border-radius: 5px;

        &.Approved{
            color: green;
            background-color: rgba(0, 128, 0, 0.212);
        }
        &.Pending{
            color: goldenrod;
            background-color: rgba(255, 255, 0, 0.212);
        }
    }
}