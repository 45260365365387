.sidebar{
    flex: 1;
    border-right: 0.5px solid rgb(235, 235, 235);
    height: 100%;
    border-radius: 10px;
    background-color: white;

    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logoimage{
            width: 20px;
        }

        .logo{
            font-size: 20px;
            font-weight: bold;
            color: #ff7373;
        }

    }

    hr{
        height: 0;
        border: 0.1px solid rgb(235, 235, 235);
    }

    .center{
        padding-left: 10px;

        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            .title{
                font-size: 15px;
                font-weight: bold;
                color: #999;
                margin: 15px 0 5px 0;
            }

            li{
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;


                &:hover{
                    background-color: #ffe8e8;
                }

                .icon{
                    font-size: 18px;
                    color: #ff7373;
                }

                span{
                    font-size: 20px;
                    font-weight: 600;
                    color: gray;
                    margin-left: 10px;
                }
            }

        }
    }


    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #ff7373;
            margin: 5px;
            cursor: pointer;

            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: #333;
            }
        }
    }
}