.new {
    width: 100%;
    height: 100vh;
    display: flex;

    .newContainer {
        flex: 6;

        .top,
        .bottom {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            margin: 20px;
            display: flex;

            h1 {
                color: lightgray;
                font-size: 24px;
            }

            .left {
                flex: 1;
                text-align: center;

                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .right {
                flex: 2;

                form {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    gap: 20px;

                    .formInput {
                        width: 40%;

                        label {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            .icon {
                                cursor: pointer;
                                font-size: 25px;
                                color: #ff7373;
                            }
                        }

                        input {
                            width: 100%;
                            border: none;
                            padding: 5px;
                            border-bottom: 1px solid #ff7373;
                            font-size: 15px;
                            outline: none;
                            color: #ff7373;
                        }
                    }
                    button{
                        width: 250px;
                        padding: 10px;
                        margin-top: 10px;
                        border: none;
                        background-color: #ff7373;
                        color: white;
                        font-size: 18px;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}